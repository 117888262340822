module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Martin Youssef - Web Developer and Designer","short_name":"Martin Youssef","start_url":"/","background_color":"#1B3078","theme_color":"#F8E8B3","display":"minimal-ui","icon":"src/images/martinLogo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
